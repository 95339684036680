<template>
  <div>
    <Banner :imageList="bannerImages" :showSlider="showTopSlider" type="home" :hideMoble="true" />
    <search-tab />
    <ContentLoading type="banner" v-if="!loaded"/>
    <h1 class="flying-advertisement text-center mb-0" v-if="!hideCondition">{{ isEmalonMode ? $t("home.emalon-advertisement") : $t("home.flying-advertisement")}}</h1>
    <TopBanner :topBanner="topBanner" v-if="loaded && showTopBanner && topBanner.length>0 && !hideCondition"/>

    <div v-if="loaded && !dealData && !hideCondition" class="loading-message">{{ $t('home.deal-loading-message') }}</div>
    <Lesiurpackages :promotionData="dealData" v-if="loaded && dealData && !!dealData.length && !hideCondition && !hideSlider" />
    <ContentLoading v-if="loaded && !dealData && !hideCondition" type="deal" />
    <Lesiurtabs
      :dealType="dealType"
      :dealData="dealData"
      :banner="middleBanner"
      :bannerHref="middleBannerHref"
      :showBanner="showMiddleBanner"
      v-if="loaded && !!dealData && !!middleBanner && !hideCondition"
    />
    <Footer page="home" v-if="!hideFooter"/>

  </div>
</template>

<script>
import TemplateHome from './TemplateHome';

export default {
  name: 'Home0',
  mixins: [TemplateHome],
  components: {
    Banner: () => import('@/components/content/Banner'),
    Lesiurpackages: () => import('@/components/home/Lesiurpackages'),
    Lesiurtabs: () => import('@/components/home/lesiurtabs/LesiurtabsTheme0'),
    Footer: () => import('@/components/content/footer/FooterTheme0'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    TopBanner: () => import('@/components/home/topBanner/TopBannerTheme0'),
    SearchTab: () => import('@/components/search/searchTabs/SearchTabsTheme0'),
  },
};
</script>

<style scoped>
  .flying-advertisement {
    font-family: "ploni";
    font-weight: bold;
    font-size: 3rem;
    text-transform: capitalize;
    margin-top: 70px;
  }

@media (max-width: 767px) {
  .flying-advertisement {
    font-size: 2rem;
    padding: 5px;
    margin-top: 0px;
  }
}
</style>
