import { mapGetters } from 'vuex';
import getBannerImage from '@/mixins/getBannerImage';

export default {
  name: 'TemplateHome',
  props: {
    msg: String,
  },
  mixins: [getBannerImage],
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      dealType: 'GET_DEAL_TYPE',
      dealData: 'GET_DEAL_DATA',
      hideCondition: 'GET_HIDE_CONDITION',
      marketerId: 'GET_MARKETER_ID',
      agencyMarketerContent: 'GET_MARKETER_AGENCY_CONTENT',
      isEmalonMode: 'GET_IS_EMALON_MODE',
    }),
    loaded() {
      let loadedBannerImages = false;
      if ((!this.showTopSlider || (this.showTopSlider && this.bannerImages?.length > 0))
        && (!this.showTopBanner || (this.showTopBanner && this.topBanner?.length > 0))
        && (!this.showMiddleBanner || (this.showMiddleBanner && this.middleBanner?.length > 0))) loadedBannerImages = true;
      return loadedBannerImages;
    },
    hideFooter() {
      const footerVisibility = this.agencyMarketerContent?.marketerSpecificContents?.footerVisibility ?? 1;
      const hide = this.hideCondition || (this.marketerId !== '' && footerVisibility === 0);
      return hide;
    },
    hideSlider() {
      return this.agencyMarketerContent?.marketerSpecificContents?.hideDealsSlider ?? false;
    },
  },
  data() {
    return {
    };
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'home');
    if (!this.hideCondition) { await this.fetchDealData(); }
  },
  methods: {
    async fetchDealData() {
      await this.$store.dispatch('FETCH_DEAL_TYPE_LIST');
      await this.$store.dispatch('FETCH_DEAL_DATA');
    },
  },
};
